import { captureException, captureMessage, withScope } from '@sentry/nextjs';
import type { ScopeContext } from '@sentry/types';

import type { LogMetric } from './metrics';

export type LogLevel = keyof typeof LOG_LEVELS;

export type LoggerContext = Omit<ScopeContext, 'tags'> & {
  tags: {
    metricName?: LogMetric;
    [key: string]: unknown;
  };
};

const LOG_LEVELS = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
} as const;

const DEFAULT_LOG_LEVEL: LogLevel = 'error';

export const isLogLevel = (level: string): level is LogLevel =>
  Object.keys(LOG_LEVELS).includes(level);

export class Logger {
  private context: Partial<LoggerContext>;

  private level: LogLevel;

  constructor(level?: string, context?: Partial<LoggerContext>) {
    this.context = context;
    this.level = isLogLevel(level) ? level : DEFAULT_LOG_LEVEL;
  }

  private shouldLog(cmdLevel: LogLevel): boolean {
    return LOG_LEVELS[this.level] <= LOG_LEVELS[cmdLevel];
  }

  getLevel(): LogLevel {
    return this.level;
  }

  withContext(context: Partial<LoggerContext>): Logger {
    return new Logger(this.level, context);
  }

  debug(...args: unknown[]): void {
    if (!this.shouldLog('debug')) {
      return;
    }

    console.debug(...args);
  }

  info(message: string, ...args: unknown[]): void {
    if (!this.shouldLog('info')) {
      return;
    }

    console.info(message, ...args);

    if (process.env.NODE_ENV === 'production') {
      withScope((scope) => {
        const { extra, tags } = this.context || {};

        if (extra) {
          scope.setExtras(extra);
        }

        if (tags) {
          scope.setTags(tags as ScopeContext['tags']);
        }

        captureMessage(message, {
          level: 'info',
        });
      });
    }
  }

  warn(message: string, ...args: unknown[]): void {
    if (!this.shouldLog('warn')) {
      return;
    }

    console.warn(message, ...args);

    if (process.env.NODE_ENV === 'production') {
      withScope((scope) => {
        const { extra, tags } = this.context || {};

        if (extra) {
          scope.setExtras(extra);
        }

        if (tags) {
          scope.setTags(tags as ScopeContext['tags']);
        }

        captureMessage(message, {
          level: 'warning',
        });
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  error(err: Error, message?: string, context?: Partial<ScopeContext>): void {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
      console.error(message, err, context);
    }

    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    withScope((scope) => {
      const { tags, extra } = this.context || context || {};

      if (tags) {
        scope.setTags(tags as ScopeContext['tags']);
      }

      if (context) {
        scope.setContext('err_context', context);
      }

      if (extra) {
        scope.setExtras(extra);
      }

      if (message) {
        scope.setExtra('err_message', message);
      }

      captureException(err);
    });
  }
}

const logger = new Logger(process.env.NEXT_PUBLIC_LOG_LEVEL);

export default logger;
