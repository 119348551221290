import {
  captureException,
  captureMessage,
  SeverityLevel,
} from '@sentry/nextjs';

const COLOR = {
  reset: '\x1b[0m',
  bright: '\x1b[1m',
  dim: '\x1b[2m',
  underscore: '\x1b[4m',
  blink: '\x1b[5m',
  reverse: '\x1b[7m',
  hidden: '\x1b[8m',

  fgBlack: '\x1b[30m',
  fgRed: '\x1b[31m',
  fgGreen: '\x1b[32m',
  fgYellow: '\x1b[33m',
  fgBlue: '\x1b[34m',
  fgMagenta: '\x1b[35m',
  fgCyan: '\x1b[36m',
  fgWhite: '\x1b[37m',

  bgBlack: '\x1b[40m',
  bgRed: '\x1b[41m',
  bgGreen: '\x1b[42m',
  bgYellow: '\x1b[43m',
  bgBlue: '\x1b[44m',
  bgMagenta: '\x1b[45m',
  bgCyan: '\x1b[46m',
  bgWhite: '\x1b[47m',
};

export const info = (message: string): void => {
  if (process.env.NODE_ENV === 'development') {
    console.info(`${COLOR.fgCyan}[INFO] - ${message}${COLOR.reset}`);
  }

  if (process.env.NODE_ENV === 'production') {
    captureMessage(message, 'info' as SeverityLevel);
  }
};

export function error(error: Error, message?: string): void {
  if (process.env.NODE_ENV === 'development') {
    console.log(
      `${COLOR.fgRed}[ERROR] - ${message || error.message}${COLOR.reset}`,
    );
  }
  if (process.env.NODE_ENV === 'production') {
    captureException(error);
  }
}

export const warning = (message: string): void => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(message);
  }

  if (process.env.NODE_ENV === 'production') {
    captureMessage(message, 'warning' as SeverityLevel);
  }
};
