import { useRouter } from 'next/router';
import { createContext, type FC, type ReactNode, useContext } from 'react';

import type { FeatureFlag } from 'shared/constants/FeatureFlags';
import { getValidFeatures } from 'shared/utils/feature-flags';
import type { Maybe } from 'types/util';

export const FEATURE_FLAGS_PARAM = 'ff';

const FeatureFlagsContext = createContext<Maybe<FeatureFlag[]>>([]);

export const FeatureFlagsProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { query, isReady } = useRouter();
  const validFeatures = isReady
    ? getValidFeatures(query[FEATURE_FLAGS_PARAM])
    : null;

  return (
    <FeatureFlagsContext.Provider value={validFeatures}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = (): Maybe<FeatureFlag[]> =>
  useContext<Maybe<FeatureFlag[]>>(FeatureFlagsContext);

export default FeatureFlagsContext;
