import { EVENTS } from 'shared/services/tracker/types';

import { pythia } from '../services';

import type { PythiaEvents } from './types';

const PYTHIA_EVENTS: PythiaEvents = {
  [EVENTS.EVENT_404]: {
    name: EVENTS.EVENT_404,
  },
  [EVENTS.EVENT_500]: {
    name: EVENTS.EVENT_500,
  },
};

export const handle404Event = (data = {}): void =>
  pythia.send({
    counterMetrics: [
      {
        name: PYTHIA_EVENTS[EVENTS.EVENT_404].name,
        labels: {
          ...data,
        },
      },
    ],
  });

export const handle500Event = (data = {}): void =>
  pythia.send({
    counterMetrics: [
      {
        name: PYTHIA_EVENTS[EVENTS.EVENT_500].name,
        labels: {
          ...data,
        },
      },
    ],
  });
