import type { Order } from '@commercelayer/sdk';
import type { SetValueConfig } from 'react-hook-form';

import type { AddressFieldTypes } from 'checkout/types/address';
import type { INationalIdFields } from 'shared/infra/contentful/contentful';
import type { Maybe } from 'types/util';

export enum AddressTypes {
  SAVE_ADDRESSES_REQUEST = '@address/SAVE_ADDRESSES_REQUEST',
  SAVE_ADDRESSES_SUCCESS = '@address/SAVE_ADDRESSES_SUCCESS',
  SAVE_ADDRESSES_FAILURE = '@address/SAVE_ADDRESSES_FAILURE',
  STORE_ADDRESSES = '@address/STORE_ADDRESSES',
}

export interface SaveAddressesAction {
  type: AddressTypes.SAVE_ADDRESSES_REQUEST;
  payload: SaveAddressPayload;
}

export interface SaveAddressesSuccessAction {
  type: AddressTypes.SAVE_ADDRESSES_SUCCESS;
  payload: {
    [AddressFieldTypes.shippingAddress]: AddressPayload;
    [AddressFieldTypes.billingAddress]?: AddressPayload;
  };
}

export interface SaveAddressesFailureAction {
  type: AddressTypes.SAVE_ADDRESSES_FAILURE;
  payload: {
    error: string;
  };
}

export interface StoreAddressAction {
  type: AddressTypes.STORE_ADDRESSES;
  payload: {
    address: AddressPayload;
  };
}

export type AddressActions =
  | SaveAddressesAction
  | SaveAddressesSuccessAction
  | SaveAddressesFailureAction
  | StoreAddressAction;

export interface AddressState {
  readonly shippingAddress: AddressPayload;
  readonly billingAddress: AddressPayload;
  readonly isCheckoutAddressStepFinished: boolean;
  readonly loading: boolean;
  readonly error: Maybe<string>;
}

export interface AddressPayload {
  /**
   * id represents the address entity id from CommerceLayer.
   * When user enter website for the first time, we don't have
   * this id as we don't have any shipping or billing addresses
   * This id is populated when we're fetching order details from CL or when creating a new shipping or billing address.
   * Also, we're using this id to check if we should update(id exists) or create the address(id does not exists)
   */
  id?: string;
  type: AddressFieldTypes | string;
  firstName: string;
  lastName: string;
  companyName?: string;
  phoneNumber?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
  streetName?: string;
  houseNumber?: string;
  fiscalCode?: string;
  vatId?: string;
  companyRegistrationNumber?: string;
  nationalId?: string;
  subRegion?: string;
  tax_id_type?: TaxIdType;
  metadata: {
    billingAddressSameAsShipping?: boolean;
    street_name?: string;
    house_number?: string;
  } & Record<string, unknown>;
}

export interface SaveAddressPayload {
  shippingAddress: AddressPayload;
  billingAddress?: AddressPayload;
  billingAddressSameAsShipping: boolean;
  loadLocalOrderFn?: () => Promise<Order>;
  onFinished?: () => Promise<void>;
}

export type UseFormSetValue = (
  name: never,
  value: unknown,
  config?: SetValueConfig,
) => void;

export type TaxIdType = INationalIdFields['documentType'] | '';
