import Pythia, { getBrowserTags } from '@sumup/pythia';

import logger from 'shared/services/logger';
import isServer from 'shared/utils/is-server';

type MetricsClient = { send: (event: unknown) => void };

const PYTHIA_API_KEY = 'AB7D56F7-8064-4DC3-B2AF-6AB8961EAB85';
const PYTHIA_APP_NAME = 'storefrontweb';
const DEVELOPMENT_ENVIRONMENT = 'development';
const PRODUCTION_ENVIRONMENT = 'production';

function createMetricsClient(): MetricsClient {
  if (isServer) {
    return {
      send: (): void => logger.debug('Pythia SDK cannot be used on server'),
    };
  }

  const env = process.env.NEXT_PUBLIC_ENVIRONMENT || DEVELOPMENT_ENVIRONMENT;

  if (env === DEVELOPMENT_ENVIRONMENT) {
    return {
      send: (event: unknown): void =>
        logger.info('Pythia metrics event:', event),
    };
  }

  if (env === PRODUCTION_ENVIRONMENT) {
    const browserTags = getBrowserTags();

    return new Pythia({
      env,
      defaultTags: { appName: PYTHIA_APP_NAME, ...browserTags },
      apiToken: PYTHIA_API_KEY,
    });
  }

  return { send: (): void => {} };
}

export const pythia = createMetricsClient();
