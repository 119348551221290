import { elb } from '@elbwalker/walker.js';

export const trackOptlyUserAssignment = (userId: string): void => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    'event': 'interaction',
    'target': 'optimizely full stack',
    'action': 'assign user id',
    'target-properties': userId,
  });

  elb('optimizely-user-id assigned', {
    optimizely_user_id: userId,
  });
};
