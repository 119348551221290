import isServer from 'shared/utils/is-server';

const STAGING_URL = process.env.NEXT_PUBLIC_STAGING_URL;

export const AUTH_COOKIE_NAME = 'oidc:token';

export const getAuthCallbackUrl = (): string => {
  if (isServer) {
    return '';
  }

  if (isPreviewDeployment()) {
    return `${STAGING_URL}/api/auth/redirect-callback`;
  }

  return `${window.location.origin}/api/auth/callback`;
};

export const getAuthLogoutUrl = (): string => {
  if (isServer) {
    return '';
  }

  if (isPreviewDeployment()) {
    return STAGING_URL;
  }

  return window.location.origin;
};

export function isPreviewDeployment(url = window.location.origin): boolean {
  return url.endsWith(process.env.NEXT_PUBLIC_VERCEL_DEPLOYMENT_SUFFIX);
}
