export enum PythiaStatuses {
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
}

export interface PythiaEvents {
  [eventName: string]: {
    name: string;
    status?: PythiaStatuses;
  };
}
