import {
  type Client,
  LogLevel as OptimizelyLogLevel,
  createInstance,
  enums,
} from '@optimizely/optimizely-sdk';

import logger, {
  type LogLevel as LoggerLogLevel,
} from 'shared/services/logger/logger';

import {
  dispatchOptimizelyInitEvent,
  dispatchOptimizelyInitFailureEvent,
} from '../tracker/events';

import { handleDecisionEvent } from './decision-event';

const LOGGER_TO_OPTIMIZELY_LOG_LEVEL: Record<
  LoggerLogLevel,
  OptimizelyLogLevel
> = {
  debug: OptimizelyLogLevel.DEBUG,
  info: OptimizelyLogLevel.INFO,
  warn: OptimizelyLogLevel.WARNING,
  error: OptimizelyLogLevel.ERROR,
};

let OptimizelySingleton: null | Client = null;

const createClient = (): Client => {
  void dispatchOptimizelyInitEvent();

  const client = createInstance({
    sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_KEY,
    errorHandler: {
      handleError: (error): void => {
        logger.error(error);
        void dispatchOptimizelyInitFailureEvent();
      },
    },
    logLevel: LOGGER_TO_OPTIMIZELY_LOG_LEVEL[logger.getLevel()],
  });

  if (!client) {
    throw new Error('Optimizely browser client failed to initialize');
  }

  client.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    handleDecisionEvent,
  );

  return client;
};

export const getOptimizelyClientForBrowser = (): Client => {
  if (OptimizelySingleton) {
    return OptimizelySingleton;
  }

  OptimizelySingleton = createClient();

  return OptimizelySingleton;
};
