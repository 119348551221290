import { tracker } from 'shared/services/tracker/tracker';
import { EVENTS } from 'shared/services/tracker/types';

import {
  handleManageProduct,
  handleOptimizelyInit,
  handlePurchase,
  handler,
} from './handlers';
import {
  handleAddressSearchError,
  handleCreateCheckout,
  handleCreateCheckoutFailure,
  handlePaymentCallbackInvoked,
} from './handlers/checkout';
import {
  handleCreateCustomer,
  handleCreateCustomerFailure,
} from './handlers/customer';
import { handle404Event, handle500Event } from './handlers/error';
import {
  handleOrderAttachBoletoInformation,
  handleOrderAttachBoletoInformationFailure,
  handleOrderCreate,
  handleOrderCreateFailure,
  handleOrderOrderStatus,
  handleOrderOrderStatusFailure,
} from './handlers/order';

const {
  SIGNUP,
  SIGNUP_FAILURE,
  LOGIN,
  LOGIN_FAILURE,
  PURCHASE,
  PURCHASE_FAILURE,
  ADD_PRODUCT,
  ADD_PRODUCT_FAILURE,
  CHANGE_PRODUCT_QUANTITY,
  CHANGE_PRODUCT_QUANTITY_FAILURE,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_FAILURE,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_FAILURE,
  CREATE_CHECKOUT,
  CREATE_CHECKOUT_FAILURE,
  ORDER_CREATED,
  ORDER_CREATED_FAILURE,
  ORDER_PLACED,
  ORDER_PLACEMENT_FAILURE,
  ATTACH_BOLETO_INFORMATION,
  ATTACH_BOLETO_INFORMATION_FAILED,
  PAYMENT_CALLBACK_INVOKED,
  OPTIMIZELY_INIT,
  OPTIMIZELY_INIT_FAILURE,
  EVENT_404,
  EVENT_500,
  SEARCH_ADDRESS_FAILURE,
  ORDER_STATUS_FETCHED,
  ORDER_STATUS_FETCH_FAILED,
  ECOM_CLIENT_INIT,
  ECOM_CLIENT_INIT_FAILURE,
} = EVENTS;

tracker.listen(SIGNUP, handler(SIGNUP));
tracker.listen(SIGNUP_FAILURE, handler(SIGNUP_FAILURE));

tracker.listen(LOGIN, handler(LOGIN));
tracker.listen(LOGIN_FAILURE, handler(LOGIN_FAILURE));

tracker.listen(PURCHASE, handlePurchase(PURCHASE));
tracker.listen(PURCHASE_FAILURE, handlePurchase(PURCHASE_FAILURE));

tracker.listen(ADD_PRODUCT, handleManageProduct(ADD_PRODUCT));
tracker.listen(ADD_PRODUCT_FAILURE, handleManageProduct(ADD_PRODUCT_FAILURE));

tracker.listen(
  CHANGE_PRODUCT_QUANTITY,
  handleManageProduct(CHANGE_PRODUCT_QUANTITY),
);
tracker.listen(
  CHANGE_PRODUCT_QUANTITY_FAILURE,
  handleManageProduct(CHANGE_PRODUCT_QUANTITY_FAILURE),
);

tracker.listen(REMOVE_PRODUCT, handleManageProduct(REMOVE_PRODUCT));
tracker.listen(
  REMOVE_PRODUCT_FAILURE,
  handleManageProduct(REMOVE_PRODUCT_FAILURE),
);

tracker.listen(CREATE_CUSTOMER, handleCreateCustomer);
tracker.listen(CREATE_CUSTOMER_FAILURE, handleCreateCustomerFailure);

tracker.listen(CREATE_CHECKOUT, handleCreateCheckout);
tracker.listen(CREATE_CHECKOUT_FAILURE, handleCreateCheckoutFailure);

tracker.listen(ORDER_CREATED, handleOrderCreate);
tracker.listen(ORDER_CREATED_FAILURE, handleOrderCreateFailure);

tracker.listen(ORDER_PLACED, handler(ORDER_PLACED));
tracker.listen(ORDER_PLACEMENT_FAILURE, handler(ORDER_PLACEMENT_FAILURE));

tracker.listen(PAYMENT_CALLBACK_INVOKED, handlePaymentCallbackInvoked);

tracker.listen(ATTACH_BOLETO_INFORMATION, handleOrderAttachBoletoInformation);
tracker.listen(
  ATTACH_BOLETO_INFORMATION_FAILED,
  handleOrderAttachBoletoInformationFailure,
);

tracker.listen(OPTIMIZELY_INIT, handleOptimizelyInit(OPTIMIZELY_INIT));
tracker.listen(
  OPTIMIZELY_INIT_FAILURE,
  handleOptimizelyInit(OPTIMIZELY_INIT_FAILURE),
);

tracker.listen(EVENT_404, handle404Event);
tracker.listen(EVENT_500, handle500Event);

tracker.listen(SEARCH_ADDRESS_FAILURE, handleAddressSearchError);

tracker.listen(ORDER_STATUS_FETCHED, handleOrderOrderStatus);
tracker.listen(ORDER_STATUS_FETCH_FAILED, handleOrderOrderStatusFailure);

tracker.listen(ECOM_CLIENT_INIT, handler(ECOM_CLIENT_INIT));
tracker.listen(ECOM_CLIENT_INIT_FAILURE, handler(ECOM_CLIENT_INIT_FAILURE));
