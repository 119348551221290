import type NinetailedPrivacyPlugin from '@ninetailed/experience.js-plugin-privacy';
import type { EventType } from '@ninetailed/experience.js-shared';

export type WindowNinetailedWithPrivacyPlugin = Window['ninetailed'] &
  NinetailedPrivacyPlugin['methods'];

export function setNinetailedConsent(consent: boolean): void {
  if (window.ninetailed) {
    (window.ninetailed as WindowNinetailedWithPrivacyPlugin).consent(consent);
  }
}

// https://docs.ninetailed.io/frameworks/privacy-plugin
export function getNinetailedPrivacyPluginOptions(hasConsent: boolean): {
  allowedEvents: EventType[];
  allowedPageEventProperties: string[];
  allowedTrackEvents?: string[];
  allowedTrackEventProperties?: string[];
  allowedTraits?: string[];
} {
  return hasConsent
    ? {
        allowedEvents: ['page', 'track', 'identify'],
        allowedPageEventProperties: ['*'],
        allowedTrackEvents: ['*'],
        allowedTrackEventProperties: ['*'],
        allowedTraits: ['*'],
      }
    : {
        allowedEvents: [],
        allowedPageEventProperties: [],
      };
}

export function getNinetailedSsrPluginOptions(): {
  cookie: { domain: string; expires: number };
} {
  return {
    cookie: {
      domain: getNinetailedCookieDomain(),
      expires: 90, // days
    },
  };
}

// Ninetailed SSR plugin expects domain to not contain dot notation (BAD: .sumup.com, GOOD: sumup.com)
// as per https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#define_where_cookies_are_sent
export function getNinetailedCookieDomain(): string {
  const environment = process.env.ENVIRONMENT || 'preview';
  const domainFromEnv = process.env.NEXT_PUBLIC_NINETAILED_COOKIE_DOMAIN;

  if (domainFromEnv) {
    return domainFromEnv;
  }

  if (environment === 'production') {
    return 'sumup.com';
  }

  if (environment === 'preview') {
    return 'sumup-vercel.app';
  }

  return '';
}
