import type { ProductGalleryItem } from 'shared/infra/commerceLayer/prices';

/**
 * ActionType types
 */
export enum CatalogTypes {
  PROVIDE_CATALOG = '@catalog/PROVIDE_CATALOG',
}

/**
 * Action Creator types
 */
/* Actions to handle the Catalog */
export interface ProvideCatalogAction {
  type: CatalogTypes.PROVIDE_CATALOG;
  payload: CatalogState;
}

export type CatalogActions = ProvideCatalogAction;

/**
 * State types
 */
export interface CatalogState {
  readonly [contentfulReferenceId: string]: ProductGalleryItem;
}
