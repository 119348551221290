import {
  createContext,
  useContext,
  type FunctionComponent,
  type ReactNode,
} from 'react';

export const ContentContext = createContext({});

export const ContentProvider: FunctionComponent<{
  content: unknown;
  children: ReactNode;
}> = ({ children, content }) => (
  <ContentContext.Provider value={content}>{children}</ContentContext.Provider>
);

export default ContentContext;

export const useContent = (): unknown => useContext<unknown>(ContentContext);
