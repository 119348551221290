import type { authenticate } from '@commercelayer/js-auth';

import { sleepMs } from './sleep';
import { Token } from './token';

type GetTokenReq = {
  marketCode: string;
  clientId: string;
};

type AuthFn = typeof authenticate;

type TokenStore = {
  get(marketCode: string): Promise<Token | void>;
  set(token: Token): Promise<void>;
};

export type Config = {
  refreshBackoffMs: number;
  refreshRetryAttempts: number;
};

export class TokenSource {
  private store: TokenStore;

  private authFn: AuthFn;

  private config: Config;

  private inFlight: Promise<Token> | null;

  constructor(store: TokenStore, authFn: AuthFn, config: Config) {
    this.store = store;
    this.authFn = authFn;
    this.config = config;
    this.inFlight = null;
  }

  async getToken(req: GetTokenReq): Promise<Token> {
    if (this.inFlight !== null) {
      return this.inFlight;
    }

    try {
      this.inFlight = this.requestToken(req);

      return await this.inFlight;
    } finally {
      this.inFlight = null;
    }
  }

  private async requestToken(req: GetTokenReq): Promise<Token> {
    const token = await this.store.get(req.marketCode);

    if (!token) {
      return this.requestNewToken(req.marketCode, req.clientId);
    }

    if (
      token.marketCode !== req.marketCode ||
      token.clientId !== req.clientId
    ) {
      return this.requestNewToken(req.marketCode, req.clientId);
    }

    if (token.isExpired()) {
      return this.refreshExistingToken(token);
    }

    return token;
  }

  private async requestNewToken(
    marketCode: string,
    clientId: string,
  ): Promise<Token> {
    const auth = await this.authFn('client_credentials', {
      clientId,
      scope: `market:code:${marketCode}`,
    });

    const token = Token.fromAuthResponse(auth, marketCode, clientId);

    await this.store.set(token);

    return token;
  }

  private async refreshExistingToken(
    existToken: Token,
    attempt = 1,
    backoffMs = this.config.refreshBackoffMs,
  ): Promise<Token> {
    if (attempt > this.config.refreshRetryAttempts) {
      throw new Error(
        `Failed to refresh token, max number of ${this.config.refreshRetryAttempts} attempts reached. abort!`,
      );
    }

    const token = await this.requestNewToken(
      existToken.marketCode,
      existToken.clientId,
    );

    // We will try to obtain a token different from
    // expiredToken by retrying if the returned token is still the same.
    // There seems to be an unfixed bug in the CL API that sometimes
    // returns the same token even if it is expired.
    if (token.accessToken === existToken.accessToken) {
      await sleepMs(backoffMs);

      return this.refreshExistingToken(
        existToken,
        attempt + 1,
        backoffMs + this.config.refreshBackoffMs,
      );
    }

    return token;
  }
}

export const createTokenSource = (
  store: TokenStore,
  authFn: AuthFn,
  config: Config,
): TokenSource => new TokenSource(store, authFn, config);
