import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import { retrieveGlobalLocale } from 'shared/services/OrderInformationService.globals';
import { EVENTS } from 'shared/services/tracker/types';

import { pythia } from '../services';

import { PythiaStatuses, type PythiaEvents } from './types';

export const PYTHIA_EVENTS: PythiaEvents = {
  [EVENTS.ORDER_CREATED]: {
    name: 'order_created',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.ORDER_CREATED_FAILURE]: {
    name: 'order_created',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.ATTACH_BOLETO_INFORMATION]: {
    name: 'order_attach_boleto_information',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.ATTACH_BOLETO_INFORMATION_FAILED]: {
    name: 'order_attach_boleto_information',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.ORDER_STATUS_FETCHED]: {
    name: 'order_status',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.ORDER_STATUS_FETCH_FAILED]: {
    name: 'order_status',
    status: PythiaStatuses.FAILED,
  },
};

export const generateOrderCreate =
  (event: EVENTS) =>
  (data = {}): void =>
    pythia.send({
      counterMetrics: [
        {
          name: PYTHIA_EVENTS[event].name,
          labels: {
            status: PYTHIA_EVENTS[event].status,
            country: getCountryCodeFromISOLocale(retrieveGlobalLocale()),
            ...data,
          },
        },
      ],
    });
export const handleOrderCreate = generateOrderCreate(EVENTS.ORDER_CREATED);
export const handleOrderCreateFailure = generateOrderCreate(
  EVENTS.ORDER_CREATED_FAILURE,
);

export const generateOrderAttachBoletoInformation =
  (event: EVENTS) =>
  (data = {}): void =>
    pythia.send({
      counterMetrics: [
        {
          name: PYTHIA_EVENTS[event].name,
          labels: {
            status: PYTHIA_EVENTS[event].status,
            country: getCountryCodeFromISOLocale(retrieveGlobalLocale()),
            ...data,
          },
        },
      ],
    });
export const handleOrderAttachBoletoInformation =
  generateOrderAttachBoletoInformation(EVENTS.ATTACH_BOLETO_INFORMATION);
export const handleOrderAttachBoletoInformationFailure =
  generateOrderAttachBoletoInformation(EVENTS.ATTACH_BOLETO_INFORMATION_FAILED);

export const generateOrderStatus =
  (event: EVENTS) =>
  (data = {}): void =>
    pythia.send({
      counterMetrics: [
        {
          name: PYTHIA_EVENTS[event].name,
          labels: {
            status: PYTHIA_EVENTS[event].status,
            country: getCountryCodeFromISOLocale(retrieveGlobalLocale()),
            ...data,
          },
        },
      ],
    });

export const handleOrderOrderStatus = generateOrderStatus(
  EVENTS.ORDER_STATUS_FETCHED,
);
export const handleOrderOrderStatusFailure = generateOrderStatus(
  EVENTS.ORDER_STATUS_FETCH_FAILED,
);
