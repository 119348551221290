import type { Reducer } from 'redux';

import { AddressFieldTypes } from 'checkout/types/address';
import {
  AddressTypes,
  type AddressActions,
  type AddressPayload,
  type AddressState,
} from 'shared/store/address/types';

const DEFAULT_ADDRESS: Omit<AddressPayload, 'type'> = {
  firstName: '',
  lastName: '',
  companyName: '',
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  zipCode: '',
  state: '',
  country: '',
  metadata: {},
};

const DEFAULT_SHIPPING_ADDRESS = {
  type: AddressFieldTypes.shippingAddress,
  ...DEFAULT_ADDRESS,
};

const DEFAULT_BILLING_ADDRESS = {
  type: AddressFieldTypes.billingAddress,
  ...DEFAULT_ADDRESS,
};

export const INITIAL_STATE: Readonly<AddressState> = {
  [AddressFieldTypes.shippingAddress]: DEFAULT_SHIPPING_ADDRESS,
  [AddressFieldTypes.billingAddress]: DEFAULT_BILLING_ADDRESS,
  isCheckoutAddressStepFinished: false,
  error: null,
  loading: false,
};

export const addresses: Reducer<AddressState, AddressActions> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case AddressTypes.STORE_ADDRESSES:
      return {
        ...state,
        [action.payload.address.type]: action.payload.address,
        loading: false,
        error: null,
      };
    case AddressTypes.SAVE_ADDRESSES_REQUEST:
      return {
        ...state,
        loading: true,
        isCheckoutAddressStepFinished: false,
        error: null,
      };
    case AddressTypes.SAVE_ADDRESSES_SUCCESS:
      return {
        ...state,
        [AddressFieldTypes.shippingAddress]: {
          ...DEFAULT_SHIPPING_ADDRESS,
          ...(action.payload[AddressFieldTypes.shippingAddress] || {}),
        },
        [AddressFieldTypes.billingAddress]: {
          ...DEFAULT_BILLING_ADDRESS,
          ...(action.payload[AddressFieldTypes.billingAddress] || {}),
        },
        isCheckoutAddressStepFinished: true,
        loading: false,
        error: null,
      };
    case AddressTypes.SAVE_ADDRESSES_FAILURE:
      return {
        ...state,
        isCheckoutAddressStepFinished: false,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
