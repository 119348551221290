import { DEFAULT_COUNTRY_CODE } from 'shared/constants/CountryConstants';
import { getAttributionParams } from 'shared/services/attribution';
import logger from 'shared/services/logger';
import type {
  AuthEventCustomData,
  BusinessAccountSelectedCustomData,
  BusinessAccountUnselectedCustomData,
  GoToWebsiteCustomData,
  InputErrorCustomData,
  ManageProductCustomData,
  MoreInfoCustomData,
  PageviewHandlerCustomData,
  PurchaseEventCustomData,
  PurchaseFailureEventCustomData,
  SkipShopEventCustomData,
  VoucherAppliedCustomData,
} from 'shared/services/tracker/types';

import { parsePrice } from '../shared';

import { sendEvent, sendPageview } from './services';

/**
 * PAGEVIEW handler
 * @param customData
 */
export function pageviewHandler(customData?: PageviewHandlerCustomData): void {
  sendPageview(customData);
}

/**
 * ADD_PRODUCT handler
 * @param customData
 */
export function addProductHandler(customData: ManageProductCustomData): void {
  const { product } = customData;
  const price = parsePrice(product.price);

  sendEvent({
    action: 'addToCart',
    category: 'Ecommerce',
    ecommerce: {
      add: {
        products: [
          {
            name: product.trackingId || product.id,
            price,
            quantity: product.quantity.toString(),
          },
        ],
      },
    },
  });
}

/**
 * SIGNUP handler
 * @param customData
 */
export function signupHandler(customData: AuthEventCustomData): void {
  const {
    merchantCode,
    country = DEFAULT_COUNTRY_CODE,
    referrer,
    email,
    userId,
  } = customData;
  const { referral_code: referralCode } = getAttributionParams();
  const referAction = referrer || referralCode;

  sendEvent({
    action: merchantCode,
    category: 'Signup',
    label: country,
    email,
    userId,
  });

  if (referAction) {
    sendEvent({
      action: referAction,
      category: 'ReferralSignup',
      label: merchantCode,
      userId,
    });
  }
}

/**
 * LOGIN handler
 * @param customData
 */
export function loginHandler(): void {
  sendEvent({
    action: 'login',
    category: 'shop',
  });
}

/**
 * PURCHASE handler (dispatches 'checkout' dataLayer category)
 * @param customData
 */
export function purchaseCheckoutHandler(
  customData: PurchaseEventCustomData,
): void {
  const { order, currencyCode = 'USD' } = customData;
  const products = order.products.map((product) => ({
    name: product.trackingId || product.id,
    price: parsePrice(product.price),
    quantity: product.quantity.toString(),
  }));

  sendEvent({
    category: 'Ecommerce',
    action: 'Checkout',
    ecommerce: {
      currencyCode,
      checkout: {
        actionField: { step: 3 },
        products,
      },
    },
    email: customData?.email,
    phoneNumber: customData?.phoneNumber,
  });
}

/**
 * PURCHASE handler (dispatches 'checkout_success' dataLayer category)
 * @param customData
 */
export function purchaseCheckoutSuccessHandler(
  customData: PurchaseEventCustomData,
): void {
  try {
    const { paymentMethod, order, couponCode } = customData;

    // REVIEW: Nowadays, the Legacy Shop always send the affiliation as 'dashboard';
    const affiliation = 'dashboard';

    const products = order.products.map((product) => ({
      name: product.trackingId || product.id,
      price: parsePrice(product.price),
      quantity: product.quantity.toString(),
    }));

    sendEvent({
      action: paymentMethod,
      category: 'checkout_success',
      label: affiliation,
      ecommerce: {
        purchase: {
          actionField: {
            id: order.id,
            affiliation,
            revenue: order.total,
            coupon: couponCode || '',
          },
          products,
        },
      },
      email: customData?.email,
      phoneNumber: customData?.phoneNumber,
    });
  } catch (error) {
    logger.error(
      error,
      `Unable to fire checkout_success event: ${JSON.stringify(customData)}`,
    );
  }
}

/**
 * PURCHASE_FAILURE handler
 * @param customData
 */
export function purchaseFailureHandler(
  customData: PurchaseFailureEventCustomData,
): void {
  const { paymentMethod, errorCode, errorMessage } = customData;
  const label = errorCode
    ? `Failed to process ${paymentMethod} - Failure reason: ${errorCode} - ${errorMessage}`
    : `Failed to process ${paymentMethod} with unknown error or 3DS validation failure`;

  sendEvent({
    action: paymentMethod,
    category: 'checkout_failed',
    label,
  });
}

/**
 * GO_TO_ACTIVATION handler
 * @param customData
 */
export function goToActivationHandler(): void {
  sendEvent({
    action: 'go_complete_account',
    category: 'shop',
  });
}

/**
 * GO_TO_CHECKOUT handler
 * @param customData
 */
export function goToCheckoutHandler(): void {
  sendEvent({
    action: 'go_to_checkout',
    category: 'shop',
  });
}

export function proceedAsRegisteredUserHandler(): void {
  sendEvent({
    action: 'proceed_as_registered_user',
    category: 'shop',
  });
}

/**
 * MORE_INFO handler
 * @param customData
 */
export function moreInfoHandler(customData: MoreInfoCustomData): void {
  const { from: label } = customData;
  sendEvent({
    action: 'more_info',
    category: 'shop',
    label,
  });
}

/**
 * SKIP_SHOP handler
 */
export function skipShopHandler(
  customData: SkipShopEventCustomData = {},
): void {
  const label = customData.label || '';
  sendEvent({
    action: 'skip_shop',
    category: 'shop',
    label,
  });
}

/**
 * GO_TO_PAYMENT handler
 */
export function goToPaymentHandler(): void {
  sendEvent({
    action: 'go_to_payment',
    category: 'shop',
  });
}

/**
 * VOUCHER_APPLIED handler
 */
export function voucherAppliedHandler(
  customData: VoucherAppliedCustomData,
): void {
  const { couponCode: label } = customData;
  sendEvent({
    action: 'voucher_applied',
    category: 'shop',
    label,
  });
}

/**
 * INPUT_ERROR handler
 * @param customData
 */
export function inputErrorHandler(customData: InputErrorCustomData): void {
  const { errorField: label } = customData;

  sendEvent({
    action: 'input_error',
    category: 'shop',
    label,
  });
}

/**
 * GO_TO_WEBSITE handler
 */
export function goToWebsiteHandler(customData: GoToWebsiteCustomData): void {
  sendEvent({
    action: 'go_to_website',
    category: 'shop',
    label: customData.link,
  });
}

/**
 * BA_LEARN_MORE_OPEN handler
 */
export function businessAccountLearnMoreOpenHandler(): void {
  sendEvent({
    action: 'ba_learn_more_open',
    category: 'shop',
    label: 'ba_learn_more',
  });
}

/**
 * BA_LEARN_MORE_CLOSE handler
 */
export function businessAccountLearnMoreCloseHandler(): void {
  sendEvent({
    action: 'ba_learn_more_close',
    category: 'shop',
    label: 'ba_learn_more',
  });
}

/**
 * BA_SELECTED handler
 */
export function businessAccountSelectedHandler(
  customData: BusinessAccountSelectedCustomData,
): void {
  const { label } = customData;
  sendEvent({
    action: 'ba_selected',
    category: 'shop',
    label,
  });
}

/**
 * BA_UNSELECTED handler
 */
export function businessAccountUnselectedHandler(
  customData: BusinessAccountUnselectedCustomData,
): void {
  const { label } = customData;
  sendEvent({
    action: 'ba_unselected',
    category: 'shop',
    label,
  });
}

/**
 * BA_CONDITIONS_MODAL_OPEN handler
 */
export function businessAccountConditionsModalOpenHandler(): void {
  sendEvent({
    action: 'ba_conditions_modal_open',
    category: 'shop',
    label: 'ba_conditions_modal',
  });
}

/**
 * BA_CONDITIONS_MODAL_CLOSE handler
 */
export function businessAccountConditionsModalCloseHandler(): void {
  sendEvent({
    action: 'ba_conditions_modal_close',
    category: 'shop',
    label: 'ba_conditions_modal',
  });
}
