import type { Channel } from 'shared/constants/Channel';
import { retrieveGlobalCatalog } from 'shared/services/OrderInformationService.globals';
import isServer from 'shared/utils/is-server';

/**
 * Get channel based on the pathname the application is accessed by
 * pathname === /dashboard/product-selection => channel === DASHBOARD
 * pathname === /point-of-sale/product-selection => channel === POINT_OF_SALE
 * we are defaulting to the signup channel
 * pathname === /product-selection => channel === SIGNUP
 * @returns {Channel}
 */
export const getChannelFromSource = (url?: string): Channel => {
  if (url) {
    return getChannelFromUrl(url);
  }

  if (isServer) {
    return retrieveGlobalCatalog() || 'signup';
  }

  return getChannelFromUrl(window.location.pathname);
};

/**
 * @param {string} pathname - Link where to redirect
 * @returns string Final link considering the channel (dashboard | signup)
 */
export function getChannelLink(pathname: string): string {
  if (pathname.charAt(0) !== '/') {
    throw new Error('Only relative pathname is accepted!');
  }

  const channelFromSource = getChannelFromSource();
  return channelFromSource === 'signup'
    ? pathname
    : `/${channelFromSource}${pathname}`;
}

export const removeProductIdFromQuery = (
  query: Record<string, string | string[]>,
): Record<string, string | string[]> => {
  const queryCopy = { ...query };
  delete queryCopy['product-id'];
  return queryCopy;
};

function getChannelFromUrl(url: string): Channel {
  const dashboardShopPathParam = '/dashboard/';
  const postShopPathParam = '/point-of-sale/';
  const quotesParam = '/quotes/';
  const websiteParam = '/website/';

  const isDashboard = url.includes(dashboardShopPathParam);
  if (isDashboard) {
    return 'dashboard';
  }

  const isPOS = url.includes(postShopPathParam);
  if (isPOS) {
    return 'point-of-sale';
  }

  const isQuotes = url.includes(quotesParam);
  if (isQuotes) {
    return 'quotes';
  }
  const isWebsite = url.includes(websiteParam);
  if (isWebsite) {
    return 'website';
  }

  return 'signup';
}
