import { tracker } from 'shared/services/tracker/tracker';
import { EVENTS } from 'shared/services/tracker/types';

import * as handlers from './handlers';

const {
  ADD_PRODUCT,
  GO_TO_CART,
  GO_TO_CHECKOUT,
  GO_TO_PAYMENT,
  INPUT_ERROR,
  LOGIN,
  PURCHASE_ATTEMPT,
  PURCHASE_FAILURE,
  PURCHASE_GA4,
  SIGNUP,
  SKIP_SHOP,
  VOUCHER_APPLIED,
  OUT_OF_CATALOG_ITEM_REMOVED,
} = EVENTS;

tracker.listen(ADD_PRODUCT, handlers.addProductHandler);
tracker.listen(GO_TO_CART, handlers.goToCart);
tracker.listen(GO_TO_CHECKOUT, handlers.goToCheckoutHandler);
tracker.listen(GO_TO_PAYMENT, handlers.goToPaymentHandler);
tracker.listen(INPUT_ERROR, handlers.inputErrorHandler);
tracker.listen(SIGNUP, handlers.signupHandler);
tracker.listen(LOGIN, handlers.loginHandler);
tracker.listen(PURCHASE_ATTEMPT, handlers.purchaseAttemptHandler);
tracker.listen(PURCHASE_GA4, handlers.purchaseCheckoutSuccessHandler);
tracker.listen(PURCHASE_FAILURE, handlers.purchaseFailureHandler);
tracker.listen(SKIP_SHOP, handlers.skipShopHandler);
tracker.listen(VOUCHER_APPLIED, handlers.voucherAppliedHandler);
tracker.listen(
  OUT_OF_CATALOG_ITEM_REMOVED,
  handlers.outOfCatalogItemRemovedHandler,
);
