import { Button, Headline, Select } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import type { FC, FormEvent } from 'react';

import { useChannel } from 'shared/context';
import { LOCALES } from 'tools/locales';

import {
  OptionsWrapper,
  StyledBadge,
  StyledHamburger,
  Wrapper,
} from './PreviewControls.styles';

const localeOptions = (LOCALES as string[]).map((isoLocale) => ({
  label: isoLocale,
  value: isoLocale,
}));

const shopOptions = [
  { label: 'signup', value: 'signup' },
  { label: 'dashboard', value: 'dashboard' },
];

export const PreviewControls: FC = () => {
  const { locale: selectedLocale, pathname, push, query } = useRouter();
  const channel = useChannel();
  const [isVisible, setVisible] = useState(false);

  const handleLocaleChange = useCallback(
    (event: FormEvent<HTMLSelectElement>) => {
      const newLocale = event.currentTarget.value;
      void push(
        {
          pathname,
          query,
        },
        undefined,
        { locale: newLocale },
      );
    },
    [pathname, push, query],
  );

  const handleShopChange = useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      const newChannel = event.currentTarget.value;
      if (newChannel === 'dashboard') {
        void push({
          pathname: `/dashboard${pathname}`,
          query: {
            ...query,
            channel: 'dashboard',
          },
        });
      } else {
        const { channel: _, ...queryWithoutChannel } = query;

        void push({
          pathname: pathname
            .replace('/dashboard/', '/')
            .replace('/[channel]', ''),
          query: queryWithoutChannel,
        });
      }
    },
    [pathname, push, query],
  );

  return (
    <>
      <StyledHamburger
        activeLabel="Close menu"
        inactiveLabel="Open menu"
        isActive={isVisible}
        onClick={function noRefCheck() {
          setVisible((visible) => !visible);
        }}
      />
      <StyledBadge aria-label="Preview" variant="neutral">
        Preview
      </StyledBadge>
      {isVisible && (
        <Wrapper>
          <>
            <Headline
              size="three"
              as="h6"
              css={{
                marginBottom: 'var(--cui-spacings-kilo)',
                marginTop: 'var(--cui-spacings-byte)',
              }}
            >
              Manage Preview Options
            </Headline>
            <OptionsWrapper>
              <Select
                css={{ marginBottom: 'var(--cui-spacings-mega)' }}
                label="Change locale"
                options={localeOptions}
                value={selectedLocale}
                onChange={handleLocaleChange}
              />

              <Select
                css={{ marginBottom: 'var(--cui-spacings-mega)' }}
                label="Change shop (signup / dashboard)"
                options={shopOptions}
                value={channel}
                onChange={handleShopChange}
              />
            </OptionsWrapper>

            <Button
              type="button"
              onClick={() => {
                window.location.href = '/api/preview/exit';
              }}
              variant="primary"
            >
              Exit preview mode
            </Button>
          </>
        </Wrapper>
      )}
    </>
  );
};
