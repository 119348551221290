import type {
  Client,
  OptimizelyUserContext,
  UserAttributes,
} from '@optimizely/optimizely-sdk';
import bowser from 'bowser';
import Cookie from 'js-cookie';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_COUNTRY_CODE } from 'shared/constants/CountryConstants';
import { IN_APP_PARAM } from 'shared/constants/platform';
import { getChannelFromSource } from 'shared/utils/channel-link';
import { getSumupCookieDomain } from 'utils/cookies';
import { getLast } from 'utils/querystring';

declare global {
  interface Window {
    gaGlobal?: { vid?: string };
  }
}

const EXPERIMENT_COOKIES_MAX_AGE = 365;
const MISSING_VALUE = 'unknown';
const PLATFORM = 'storefront';

export const EXPERIMENT_USER_COOKIE_NAME = 'optimizely_experiment_user';

const getAttributesFromBrowser = (
  shopCountry = DEFAULT_COUNTRY_CODE,
): UserAttributes => {
  const { referrer } = queryString.parse(window.location.search);
  const browserInfo = bowser.parse(navigator.userAgent);

  return {
    'pathname': window.location.pathname,
    'channel': getChannelFromSource(),
    'cookiestring': Object.entries(Cookie.get())
      .map(([key, value]) => `${key}=${value}`)
      .join('&'),
    'shop_country': shopCountry,
    'querystring': window.location.search,
    'from_app': window.location.search.includes(IN_APP_PARAM),
    'referrer_param': getLast(referrer) || null,
    'google-analytics-user-id':
      Cookie.get('_ga') || window?.gaGlobal?.vid || null,
    'platform': PLATFORM,
    'user-agent': navigator.userAgent,
    'browser-language':
      (navigator.languages && navigator.languages[0]) || navigator.language, // chrome && firefox || other browsers
    'browser-name': browserInfo?.browser?.name ?? MISSING_VALUE,
    'browser-version': browserInfo?.browser?.version ?? MISSING_VALUE,
    'device-type': browserInfo.platform.type ?? MISSING_VALUE,
    'os-name': browserInfo?.os?.name ?? MISSING_VALUE,
    'os-version': browserInfo?.os?.version ?? MISSING_VALUE,
  };
};

export const getUserIdFromBrowser = (): string => {
  const userId = Cookie.get(EXPERIMENT_USER_COOKIE_NAME) || uuidv4();

  Cookie.set(EXPERIMENT_USER_COOKIE_NAME, userId, {
    expires: EXPERIMENT_COOKIES_MAX_AGE,
    domain: getSumupCookieDomain(),
  });

  return userId;
};

export const getOptimizelyUserFromBrowser = (
  optimizely: Client,
  countryCode: string,
): OptimizelyUserContext => {
  const id = getUserIdFromBrowser();
  const attributes = getAttributesFromBrowser(countryCode);

  return optimizely.createUserContext(id, attributes);
};
