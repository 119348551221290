import type { Reducer } from 'redux';

import {
  PromotionsTypes,
  type PromotionsActions,
  type PromotionsState,
} from './types';

export const INITIAL_STATE: Readonly<PromotionsState> = {
  details: {},
  loading: false,
  error: false,
};

export const promotions: Reducer<PromotionsState, PromotionsActions> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case PromotionsTypes.LOAD_PROMOTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PromotionsTypes.LOAD_PROMOTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        details: action.payload.details,
      };
    case PromotionsTypes.LOAD_PROMOTIONS_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case PromotionsTypes.RESET_PROMOTIONS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
