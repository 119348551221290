type DefaultCookieDomains = {
  prod: string;
  preview: string;
  env: string;
};

const getCookieDomain = (defaultDomains: DefaultCookieDomains): string => {
  if (defaultDomains.env) {
    return defaultDomains.env;
  }

  const environment = process.env.ENVIRONMENT || 'preview';

  if (environment === 'production') {
    return defaultDomains.prod;
  }

  if (environment === 'preview') {
    return defaultDomains.preview;
  }

  return '';
};

export const getStoreCookieDomain = (): string =>
  getCookieDomain({
    prod: 'store.sumup.com',
    preview: '.sumup-vercel.app',
    env: process.env.NEXT_PUBLIC_SUMUP_COOKIE_DOMAIN,
  });

export const getSumupCookieDomain = (): string =>
  getCookieDomain({
    prod: '.sumup.com',
    preview: '.sumup-vercel.app',
    env: process.env.NEXT_PUBLIC_SUMUP_COOKIE_DOMAIN,
  });
