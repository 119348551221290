export type FlagConfig = {
  flagKey: string;
};

export type ExperimentConfig = FlagConfig & {
  control: string;
  variation: string;
  // is rule key in the optimizely interface
  experimentKey: string;
};

export const TRANSPARENT_FEE: FlagConfig = {
  flagKey: '_shop__transparent_fees',
};

export const BA_BUNDLE: FlagConfig = {
  flagKey: '_shop_website_business_account_bundle',
};

export const TTPOI_AT_TOP: FlagConfig = {
  flagKey: '_shop_ttpoi_at_top',
};

export const SHOP_MIGRATION: ExperimentConfig = {
  flagKey: '_shop__migration_feature_experimentation',
  control: 'off',
  variation: 'on',
  experimentKey: 'another_experiment',
};

export const WEBSITE_PDP_CONFIGURATOR = {
  flagKey: '_website__product_configurator',
  control: 'off',
  variation: 'on',
  experimentKey: 'website_pdp',
};

const RUNNING_TARGETS: (FlagConfig | ExperimentConfig)[] = [
  WEBSITE_PDP_CONFIGURATOR,
  SHOP_MIGRATION,
  TRANSPARENT_FEE,
  BA_BUNDLE,
  TTPOI_AT_TOP,
];

export const getRunningFlagKeys = (): string[] =>
  RUNNING_TARGETS.map((flag) => flag.flagKey);
