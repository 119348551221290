import type { Reducer } from 'redux';

import type { PaymentMethod } from 'shared/infra/commerceLayer/types';

import {
  PaymentActionTypes,
  type PaymentActions,
  type PaymentState,
} from './types';

export const INITIAL_STATE: Readonly<PaymentState> = {
  id: '',
  reference: null as unknown as PaymentMethod, // payment_method name
  checkoutId: '',
  paymentCode: '',
  documentDownloadUrl: '',
  loading: false,
  error: null,
};

export const payment: Reducer<PaymentState, PaymentActions> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case PaymentActionTypes.RESET_PAYMENT: {
      return INITIAL_STATE;
    }
    case PaymentActionTypes.ATTACH_PAYMENT_METHOD_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case PaymentActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.isLoading ?? true,
        error: null,
      };
    }
    case PaymentActionTypes.ATTACH_PAYMENT_METHOD_FAILURE:
    case PaymentActionTypes.SET_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    case PaymentActionTypes.ATTACH_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        id: action.payload.id,
        reference: action.payload.reference,
        loading: false,
        error: null,
      };
    }
    case PaymentActionTypes.ATTACH_CHECKOUT_ID: {
      return {
        ...state,
        checkoutId: action.payload.checkoutId,
      };
    }
    case PaymentActionTypes.ATTACH_PAYMENT_CODE: {
      return {
        ...state,
        paymentCode: action.payload.code,
      };
    }
    case PaymentActionTypes.ATTACH_DOWNLOAD_URL: {
      return {
        ...state,
        documentDownloadUrl: action.payload.documentDownloadUrl,
      };
    }
    default:
      return state;
  }
};
