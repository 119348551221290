import type { IElbwalker, Walker, WebDestination } from '@elbwalker/walker.js';

import { MERCHANT_ID } from 'shared/infra/cookies';

import { ENTITIES } from './constants';

type DestinationSumUpGTMFunction = WebDestination.Function<
  CustomConfig,
  CustomEventConfig
>;
type CustomConfig = Record<string, unknown>;
type CustomEventConfig = Record<string, unknown>;

export const destinationSumUpGTM: DestinationSumUpGTMFunction = {
  config: {},

  init() {
    window.dataLayer = window.dataLayer || [];

    return true;
  },

  push(event) {
    const { globals } = event;

    // Workaround / hack: merchant_code is assigned here
    // rather than being defined as a "global".
    // Currently, defining it as a "global" can occasionally lead to
    // an undefined value when retrieving from localStorage.
    globals.merchant_code = localStorage.getItem(MERCHANT_ID) || 'unknown';

    const commonProperties = {
      event_timestamp_msec: event.timestamp,
      user_agent: navigator.userAgent,
      ...globals,
    };

    const { eventName, eventBody } = mapEvent(event);

    const finalEvent = {
      ...commonProperties,
      ...eventBody,
      event: eventName,
      walker: true,
    };

    window.dataLayer.push(finalEvent);
  },
};

export function mapEvent(event: IElbwalker.Event): {
  eventName: string;
  eventBody: Record<string, any>;
} {
  // Rename events to snake_case
  const eventName = event.event.replaceAll(' ', '_').toLowerCase();

  switch (eventName) {
    case 'go_to_cart':
      return {
        eventName: 'button_clicked',
        eventBody: {
          ...event.data,
          button_description: eventName,
          items: mapNestedToItems(event.nested),
        },
      };
    case 'begin_checkout': {
      const { ...otherEventData } = event.data;
      return {
        eventName,
        eventBody: {
          ...otherEventData,
          items: mapNestedToItems(event.nested),
        },
      };
    }
    case 'input_error':
      return {
        eventName: 'input-error_shown',
        eventBody: event.data,
      };
    case 'login_complete':
      return {
        eventName: 'login',
        eventBody: event.data,
      };
    case 'product_add':
      return {
        eventName: 'add_to_cart',
        eventBody: event.data,
      };
    case 'product_clicked':
      return {
        eventName: 'button_clicked',
        eventBody: {
          button_description: event.data.button_description,
          items: [
            {
              price: event.data.price,
              item_name: event.data.name,
              quantity: 1,
            },
          ],
        },
      };
    case 'shipping_complete': {
      const { id, ...otherEventData } = event.data;
      return {
        eventName: 'add_shipping_info',
        eventBody: {
          ...otherEventData,
          transaction_id: id,
          items: mapNestedToItems(event.nested),
        },
      };
    }
    case 'payment_complete': {
      const { id, ...otherEventData } = event.data;
      return {
        eventName: 'add_payment_info',
        eventBody: {
          ...otherEventData,
          transaction_id: id,
          items: mapNestedToItems(event.nested),
        },
      };
    }
    case 'payment_success': {
      const { id, phoneNumber, ...otherEventData } = event.data;
      return {
        eventName: 'purchase',
        eventBody: {
          ...otherEventData,
          transaction_id: id,
          phone_number: phoneNumber,
          items: mapNestedToItems(event.nested),
        },
      };
    }
    case 'payment_error': {
      const { id, ...otherEventData } = event.data;
      return {
        eventName: 'payment_failed',
        eventBody: {
          ...otherEventData,
          transaction_id: id,
          items: mapNestedToItems(event.nested),
        },
      };
    }
    case 'product_view':
      return {
        eventName: 'view_item',
        eventBody: {
          items: [
            {
              price: event.data.price,
              item_name: event.data.name,
            },
          ],
        },
      };
    case 'voucher_applied':
      return {
        eventName: 'voucher-code_applied',
        eventBody: event.data,
      };
    default:
      return {
        eventName,
        eventBody: event.data,
      };
  }
}

function mapNestedToItems(nested: Walker.Entities):
  | {
      item_name: Walker.Property;
    }[]
  | undefined {
  // Get all products in merchant's cart
  // sent from walker.js' 'nested' property
  return (
    nested
      ?.filter((n) => n.type === ENTITIES.PRODUCT)
      .map((product) => {
        // Rename 'name' to 'item_name', necessarry for GA4
        const { name, ...data } = product.data;
        return { ...data, item_name: name };
      }) || []
  );
}
