import Tagger from '@elbwalker/tagger';

const tagger = Tagger();

type FunctionParams = {
  entity?: string;
  action?: {
    trigger: string;
    action: string;
  };
  properties?: {
    entity?: string;
    details: any;
  };
  globals?: any;
  context?: any;
};
export const getTaggingData = ({
  entity,
  action,
  properties,
  globals,
  context,
}: FunctionParams): Record<string, string> => {
  let res = {};

  if (entity) {
    res = tagger.entity(entity);
  }

  if (action) {
    res = {
      ...res,
      ...tagger.action(action.trigger, action.action),
    };
  }

  if (properties) {
    if (!properties.entity && !entity) {
      throw new Error('Entity is required');
    }

    res = {
      ...res,
      ...tagger.property(properties.entity || entity, properties.details),
    };
  }

  if (globals) {
    res = {
      ...res,
      ...tagger.globals(globals),
    };
  }
  if (context) {
    res = {
      ...res,
      ...tagger.context(context),
    };
  }

  return res;
};
