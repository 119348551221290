import type { Promotion } from 'shared/infra/commerceLayer/promotions';

/**
 * Action types
 */
export enum PromotionsTypes {
  LOAD_PROMOTIONS_REQUEST = '@coupons/LOAD_PROMOTIONS_REQUEST',
  LOAD_PROMOTIONS_SUCCESS = '@coupons/LOAD_PROMOTIONS_SUCCESS',
  LOAD_PROMOTIONS_FAILURE = '@coupons/LOAD_PROMOTIONS_FAILURE',
  RESET_PROMOTIONS = '@coupons/RESET_PROMOTIONS',
}

export interface LoadPromotionsPayload {
  coupons: string[];
}

export interface LoadPromotionsAction {
  type: PromotionsTypes.LOAD_PROMOTIONS_REQUEST;
  payload: LoadPromotionsPayload;
}

export interface LoadPromotionsSuccessAction {
  type: PromotionsTypes.LOAD_PROMOTIONS_SUCCESS;
  payload: {
    details: Record<string, Promotion>;
  };
}
export interface LoadPromotionsFailureAction {
  type: PromotionsTypes.LOAD_PROMOTIONS_FAILURE;
  payload: { message?: string };
}

export interface ResetPromotionsAction {
  type: PromotionsTypes.RESET_PROMOTIONS;
}

export type PromotionsActions =
  | LoadPromotionsAction
  | LoadPromotionsSuccessAction
  | LoadPromotionsFailureAction
  | ResetPromotionsAction;

export type PromotionsState = {
  readonly loading: boolean;
  readonly error: boolean;
  readonly details: Record<string, Promotion>;
};
