import type { Ninetailed } from '@ninetailed/experience.js';

import { isFunction } from 'shared/utils/functional';

export type NinetailedWindow = Window['ninetailed'] & Ninetailed;

export const sendEvent = (eventName: string): void => {
  const ninetailed = window.ninetailed as NinetailedWindow;

  if (isFunction(ninetailed?.track)) {
    void ninetailed.track(eventName);
  }
};
