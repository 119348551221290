import type { Reducer } from 'redux';

import {
  CatalogTypes,
  type CatalogActions,
  type CatalogState,
} from 'shared/store/catalog/types';

const INITIAL_STATE: Readonly<CatalogState> = {};

export const catalog: Reducer<CatalogState, CatalogActions> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case CatalogTypes.PROVIDE_CATALOG: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
