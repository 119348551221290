import {
  createContext,
  useContext,
  type FunctionComponent,
  type ReactNode,
} from 'react';

import type { Channel } from 'shared/constants/Channel';
import { getChannelFromSource } from 'shared/utils/channel-link';

const ChannelContext = createContext(getChannelFromSource());

export const ChannelProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => (
  <ChannelContext.Provider value={getChannelFromSource()}>
    {children}
  </ChannelContext.Provider>
);

export const useChannel = (): Channel => useContext<Channel>(ChannelContext);

export default ChannelContext;
