import isServer from 'shared/utils/is-server';
import {
  DEFAULT_LOCALE,
  LOCALES,
  LOCALES_WITH_SMALL_TRAFFIC,
} from 'tools/locales';
import type { Maybe } from 'types/util';

export const containsISOLocaleLikeString = (
  string?: Maybe<string>,
): string is `${string}-${string}` =>
  /^\/(\D{2})-(\D{2})(\/)?.*/.test(string ?? '');

export const isISOLocaleLikeString = (
  string?: Maybe<string>,
): string is `${string}-${string}` => /(\D{2})-(\D{2})/.test(string ?? '');

export const getCountryCodeFromISOLocale = (
  locale = DEFAULT_LOCALE,
): string | undefined => {
  if (!isISOLocaleLikeString(locale)) {
    return undefined;
  }

  const [, country] = locale.split('-');
  return country.toUpperCase();
};

export const getLanguageFromISOLocale = (
  locale = DEFAULT_LOCALE,
): string | undefined => {
  if (!isISOLocaleLikeString(locale)) {
    return undefined;
  }

  const [language] = locale.split('-');
  return language;
};

const LOCALE_URL_REGEX = /^.*\/(\D{2}-\D{2}).*$/;

export const encounteredUnsupportedLocale = (url: string): boolean => {
  const matches = LOCALE_URL_REGEX.exec(url);
  if (!matches) {
    // didn't encounter a locale in the url
    return false;
  }
  return !(LOCALES as string[]).find(
    (supportedLocale) =>
      supportedLocale.toLowerCase() === matches[1].toLowerCase(),
  );
};

export const findAppropriateLocaleForUrl = (
  url: string,
): string | undefined => {
  const matches = LOCALE_URL_REGEX.exec(url);
  if (!matches) {
    // didn't encounter a locale in the url
    return undefined;
  }

  const locale = matches[1];
  const country = getCountryCodeFromISOLocale(locale);
  if (!country) {
    return undefined;
  }
  if (
    (LOCALES as string[])
      .map((localeToMatch) => localeToMatch.toLowerCase())
      .includes(locale.toLowerCase())
  ) {
    return locale;
  }

  return (LOCALES as string[]).find((supportedLocale) =>
    supportedLocale.toLowerCase().includes(country.toLowerCase()),
  );
};

export const findAppropriateCountryForUrl = (
  url: string,
): string | undefined => {
  const locale = findAppropriateLocaleForUrl(url);
  if (!locale) {
    return undefined;
  }
  return getCountryCodeFromISOLocale(locale);
};

export const replaceISOLocaleInUrl = (
  url: string,
  rewriteLocale: string,
): string => {
  let output = '';
  // enable rewriting without locale
  const isRewriteEmpty = rewriteLocale === '';
  if (!isRewriteEmpty && !isISOLocaleLikeString(rewriteLocale)) {
    return url;
  }
  // rewrite locale
  output = url.replace(/\D{2}-\D{2}/, rewriteLocale);
  // if there's no locale specified remove the preceeding /
  if (isRewriteEmpty) {
    output = output.replace('/', '');
  }
  return output;
};

export const removeSmallTrafficLocales = (locales: string[]): string[] =>
  locales.filter((locale) => !LOCALES_WITH_SMALL_TRAFFIC.includes(locale));

// find appropriate locale for a given country.
// ex, for FR -> fr-FR
export const findISOLocaleForCountry = (country = ''): string | undefined =>
  (LOCALES as string[]).find((locale) => locale.includes(`-${country}`));

export const readWindowPathnameWithDefaultLocale = (): string => {
  const pathname = !isServer ? window.location.pathname : null;
  return containsISOLocaleLikeString(pathname)
    ? pathname
    : // next.js is handling the default locale which might not be present on the location object
      `/${DEFAULT_LOCALE}${pathname}`;
};
