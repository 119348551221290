import { parse, stringify } from 'query-string';

import { getCookie, MERCHANT_CODE } from 'shared/infra/cookies';
import logger from 'shared/services/logger';
import { compose, isFunction, omit, pickBy } from 'shared/utils/functional';

interface InteractionEventFields {
  category?: string;
  label?: string;
  [custom: string]: unknown;
}

interface PageViewOverrides {
  ['content-name']?: string;
  [custom: string]: unknown;
}

function getCleanLocation(): string {
  const QUERY_PARAM_NOT_ALLOWED = ['email'];
  const { pathname, search } = window.location;

  const safeQueryString = compose(
    stringify,
    omit(QUERY_PARAM_NOT_ALLOWED),
    parse,
  )(search) as string;

  return safeQueryString.length ? `${pathname}?${safeQueryString}` : pathname;
}

export const send = (customData: Record<string, unknown>): void => {
  if (isFunction(window.dataLayer?.push)) {
    const merchantCode = getCookie(MERCHANT_CODE);

    const data = pickBy(
      { ...customData, Merchant_Code: merchantCode },
      (value) => !!value,
    );

    window.dataLayer.push({ ...data });

    return;
  }

  logger.error(
    new Error('datalayer is not defined'),
    JSON.stringify(customData),
  );
};

export function sendEvent({
  category,
  label = '',
  ...customFields
}: InteractionEventFields): void {
  send({
    'event': 'interaction',
    'target': category,
    'target-properties': label,
    ...customFields,
  });
}

export function sendPageview(overrides?: PageViewOverrides): void {
  send({
    'content-name': getCleanLocation(),
    ...overrides,
    'event': 'content-view',
  });
}
