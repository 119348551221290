/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import fs from 'fs';
import { tmpdir } from 'os';
import { join } from 'path';

import { sleepMs } from './sleep';
import { Token } from './token';

export const TMP_DIR_NAME = 'cl-storage';
export const ACCESS_TOKEN_KEY = 'CL_ACCESS_TOKEN';

export class SessionTokenStore {
  get(): Promise<Token | void> {
    const storedToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);

    if (storedToken) {
      return Promise.resolve(Token.fromJson(storedToken));
    }

    return undefined;
  }

  set(token: Token): Promise<void> {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, token.toJson());

    return Promise.resolve();
  }
}

// The cl auth endpoint throttles during static build time (too many requests).
// We can avoid this by caching the tokens on the file system.
// Why file system? Because each page built triggers a seperate build,
// and each build has its own memory.
export class FileTokenStore {
  get(marketCode: string): Promise<Token | void> {
    const parentFolder = join(tmpdir(), TMP_DIR_NAME);
    const cacheFilePath = join(parentFolder, `${marketCode}_token.json`);

    if (!fs.existsSync(cacheFilePath)) {
      return Promise.resolve();
    }

    const data = fs.readFileSync(cacheFilePath, { encoding: 'utf8' });
    const token = Token.fromJson(data);

    return Promise.resolve(token);
  }

  async set(token: Token): Promise<void> {
    const parentFolder = join(tmpdir(), 'cl-storage');
    const cacheFilePath = join(parentFolder, `${token.marketCode}_token.json`);

    if (!fs.existsSync(parentFolder)) {
      fs.mkdirSync(parentFolder);
    }

    fs.writeFileSync(cacheFilePath, token.toJson());

    // Avoid throttling
    await sleepMs(1000);

    return Promise.resolve();
  }

  clear(marketCode: string): void {
    const parentFolder = join(tmpdir(), 'cl-storage');
    const cacheFilePath = join(parentFolder, `${marketCode}_token.json`);

    if (!fs.existsSync(cacheFilePath)) {
      return;
    }

    fs.unlinkSync(cacheFilePath);
  }
}

export const createSessionTokenStore = (): SessionTokenStore =>
  new SessionTokenStore();

export const createFileTokenStore = (): FileTokenStore => new FileTokenStore();
