import type { Channel } from 'shared/constants/Channel';
import type { LOCALE_CODE } from 'shared/infra/contentful/contentful';
import isServer from 'shared/utils/is-server';

/*
 * This file is exported as a global file which can be accessed by independent parts of our application
 */

// we are saving the value in the global scope so it is available to the method to be used at any time
let globalLocale: LOCALE_CODE;
let globalCatalog: Channel;

export const retrieveGlobalLocale = (): LOCALE_CODE => globalLocale;
export const setGlobalLocale = (newGlobalLocale: LOCALE_CODE): void => {
  globalLocale = newGlobalLocale;
};

export const retrieveGlobalCatalog = (): Channel => globalCatalog;
// if this method is called from the server side, do nothing
// there's a different method providing support for this use case
export const setGlobalCatalog = (newGlobalCatalog: Channel): void => {
  if (!isServer) {
    globalCatalog = newGlobalCatalog;
  }
};
/*
 * We have a different source of truth for the catalog value if it is coming from the server side during build time
 * Check `getStaticProps` method for every page
 * We make sure that the value set on the server is used at build time
 * The runtime value on the client side remains the same by calling the `setGlobalCatalog` directly
 * The names are different to illustrate this point
 */
export const setGlobalCatalogFromServerSide = (
  newGlobalCatalog: Channel,
): void => {
  if (!isServer) {
    throw new Error(
      'Cannot call method setGlobalCatalogFromServer on the client side!',
    );
  }
  globalCatalog = newGlobalCatalog;
};
