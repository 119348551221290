import type { Product } from '../../types';
import { parsePrice } from '../shared';

export const createProductNestedEntity = (products: Product[]) =>
  products.map((product) => ({
    type: 'product',
    data: {
      name: product.trackingId || product.id,
      price: parsePrice(product.price),
      quantity: product.quantity,
    },
    nested: [],
    context: {},
  }));
