import CommerceLayer, {
  type CommerceLayerClient,
  type ErrorObj,
  type RequestObj,
} from '@commercelayer/sdk';

import logger from 'shared/services/logger';

import { newLock, type Lock } from './lock';
import type { Token } from './token';

type Config = {
  clientId: string;
  marketCode: string;
};

export type TokenSource = {
  getToken: (config: Config) => Promise<Token>;
};

export class Client {
  private tokenSource: TokenSource;

  private config: Config;

  private lock: Lock;

  constructor(tokenSource: TokenSource) {
    this.tokenSource = tokenSource;
    // We need to lock the client get & getAccessToken methods until the config is set.
    // We otherwise might attempt to get a token with an empty config.
    this.lock = newLock();

    this.lock.lock();
  }

  init(config: Config): void {
    this.config = config;
    this.lock.unlock();

    // We get the token here, but we don't wait for it.
    // We just want to make sure that (in most cases) the token is fetched
    // before the first CL call for performance reasons.
    void this.tokenSource.getToken(this.config);
  }

  async get(): Promise<CommerceLayerClient> {
    await this.lock.wait;

    const token = await this.tokenSource.getToken(this.config);

    const config = {
      accessToken: token.accessToken,
      organization: 'sumup',
    };

    const client = CommerceLayer(config);

    client.addRequestInterceptor((request: RequestObj): RequestObj => {
      try {
        const method = request.method.toLowerCase();
        if (['post', 'patch', 'put'].includes(method)) {
          logger
            .withContext({
              tags: {
                method: request.method,
                updated_attributes: request.data.data?.attributes
                  ? JSON.stringify(Object.keys(request.data.data.attributes))
                  : '',
                updated_relationships: request.data.data?.relationships
                  ? JSON.stringify(Object.keys(request.data.data.relationships))
                  : '',
                url: request.url,
              },
            })
            .info('Received CommerceLayer response');
        }
      } catch (e) {
        logger.error(e);
      }

      return request;
    });

    client.addResponseInterceptor(undefined, (error: ErrorObj): ErrorObj => {
      try {
        if (error.response.status !== 401) {
          return error;
        }

        const method = error.response.config.method.toLowerCase();
        if (['post', 'patch', 'put'].includes(method)) {
          logger.error(new Error('CommerceLayer 401 update error'));
        }
      } catch (e: unknown) {
        const err = e instanceof Error ? e : new Error(String(e));

        logger.error(err);
      }
      return error;
    });

    return client;
  }

  async getAccessToken(): Promise<Token> {
    await this.lock.wait;

    return this.tokenSource.getToken(this.config);
  }
}

export const createClient = (tokenSource: TokenSource): Client =>
  new Client(tokenSource);
