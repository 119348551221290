import { NinetailedProvider } from '@ninetailed/experience.js-next';
import { NinetailedGoogleTagmanagerPlugin } from '@ninetailed/experience.js-plugin-google-tagmanager';
import NinetailedPrivacyPlugin from '@ninetailed/experience.js-plugin-privacy';
import NinetailedSsrPlugin from '@ninetailed/experience.js-plugin-ssr';
import type { FC } from 'react';

import {
  getNinetailedPrivacyPluginOptions,
  getNinetailedSsrPluginOptions,
} from 'shared/services/ninetailed/setup';
import {
  NINETAILED_CLIENT_ID,
  NINETAILED_ENVIRONMENT,
  NINETAILED_IS_ENABLED,
} from 'shared/services/tracker/consumers/ninetailed/constants';
import { useCookieConsent } from 'utils/scripts/cookieConsent/cookieConsent';

export type NinetailedWrapperProps = {
  children: React.ReactNode;
};

export const NinetailedWrapper: FC<NinetailedWrapperProps> = ({ children }) => {
  const { categories: consentCategories } = useCookieConsent();

  if (!NINETAILED_IS_ENABLED) {
    return <>{children}</>;
  }

  const ninetailedPrivacyPluginOptions = getNinetailedPrivacyPluginOptions(
    consentCategories?.includes('FUNCTIONAL'),
  );
  const ninetailedSsrPluginOptions = getNinetailedSsrPluginOptions();
  const plugins = [
    new NinetailedSsrPlugin(ninetailedSsrPluginOptions),
    new NinetailedPrivacyPlugin(ninetailedPrivacyPluginOptions),
    new NinetailedGoogleTagmanagerPlugin(),
  ];

  return (
    <NinetailedProvider
      plugins={plugins}
      clientId={NINETAILED_CLIENT_ID}
      environment={NINETAILED_ENVIRONMENT}
    >
      {children}
    </NinetailedProvider>
  );
};
