import type { Order } from '@commercelayer/sdk';

import type { PaymentMethod } from 'shared/infra/commerceLayer/types';
import type { CFProductId } from 'shared/types/ids';
import type { Maybe } from 'types/util';

/**
 * ActionType types
 */
export enum OrderTypes {
  LOAD_ORDER_REQUEST = '@order/LOAD_ORDER_REQUEST',
  LOAD_ORDER_SUCCESS = '@order/LOAD_ORDER_SUCCESS',
  LOAD_ORDER_FAILURE = '@order/LOAD_ORDER_FAILURE',

  LOAD_PRODUCTS = '@order/LOAD_PRODUCTS',
  ADD_PRODUCT_REQUEST = '@order/ADD_PRODUCT_REQUEST',
  ADD_PRODUCT_SUCCESS = '@order/ADD_PRODUCT_SUCCCES',
  ADD_PRODUCT_FAILURE = '@order/ADD_PRODUCT_FAILURE',

  ADD_INVOICE_TYPE = '@order/ADD_INVOICE_TYPE',

  ADD_SINGLE_PRODUCT_REQUEST = '@order/ADD_SINGLE_PRODUCT_REQUEST',
  ADD_SINGLE_PRODUCT_SUCCESS = '@order/ADD_SINGLE_PRODUCT_SUCCESS',
  ADD_SINGLE_PRODUCT_FAILURE = '@order/ADD_SINGLE_PRODUCT_FAILURE',

  PLACE_ORDER_REQUEST = '@order/PLACE_ORDER_REQUEST',
  PLACE_ORDER_SUCCESS = '@order/PLACE_ORDER_SUCCESS',
  PLACE_ORDER_FAILURE = '@order/PLACE_ORDER_FAILURE',

  UNPLACE_ORDER_REQUEST = '@order/UNPLACE_ORDER_REQUEST',
  UNPLACE_ORDER_SUCCESS = '@order/UNPLACE_ORDER_SUCCESS',
  UNPLACE_ORDER_FAILURE = '@order/UNPLACE_ORDER_FAILURE',

  APPLY_COUPON_REQUEST = '@order/APPLY_COUPON_REQUEST',
  APPLY_COUPON_SUCCESS = '@order/APPLY_COUPON_SUCCESS',
  APPLY_COUPON_FAILURE = '@order/APPLY_COUPON_FAILURE',
  RESET_COUPON_REQUEST = '@order/RESET_COUPON_REQUEST',

  CHANGE_PRODUCT_QUANTITY = '@order/CHANGE_PRODUCT_QUANTITY',
  CHANGE_PRODUCT_QUANTITY_SUCCESS = '@order/CHANGE_PRODUCT_QUANTITY_SUCCESS',
  CHANGE_PRODUCT_QUANTITY_FAILURE = '@order/CHANGE_PRODUCT_QUANTITY_FAILURE',

  REMOVE_PRODUCT = '@order/REMOVE_PRODUCT',
  REMOVE_PRODUCT_SUCCESS = '@order/REMOVE_PRODUCT_SUCCESS',
  REMOVE_PRODUCT_FAILURE = '@order/REMOVE_PRODUCT_FAILURE',

  UPDATE_NOTIFICATION = '@order/UPDATE_NOTIFICATION',

  RESET_ORDER = '@order/RESET_ORDER',

  PROPOSE_BUSINESS_ACCOUNT = '@order/PROPOSE_BUSINESS_ACCOUNT',
  SELECT_BUSINESS_ACCOUNT = '@order/SELECT_BUSINESS_ACCOUNT',
}

/**
 * Action types
 */

/* Actions to handle the Order */
export interface LoadOrderAction {
  type: OrderTypes.LOAD_ORDER_REQUEST;
}

export interface LoadOrderSuccessAction {
  type: OrderTypes.LOAD_ORDER_SUCCESS;
  payload: OrderDetails;
}

export interface LoadOrderFailureAction {
  type: OrderTypes.LOAD_ORDER_FAILURE;
  payload: { message: string };
}

/* Actions to handle the Products in the Order */
export interface LoadProductsAction {
  type: OrderTypes.LOAD_PRODUCTS;
  payload: OrderLineItem[];
}

export interface AddProductAction {
  type: OrderTypes.ADD_PRODUCT_REQUEST;
  payload: {
    contentfulId: CFProductId;
  };
}

export interface ProposeBusinessAccountAction {
  type: OrderTypes.PROPOSE_BUSINESS_ACCOUNT;
  payload: { value: boolean };
}

export interface SelectBusinessAccountAction {
  type: OrderTypes.SELECT_BUSINESS_ACCOUNT;
  payload: { value: boolean };
}

export interface AddProductSuccessAction {
  type: OrderTypes.ADD_PRODUCT_SUCCESS;
  payload: { message: string };
}

export interface AddInvoiceTypeAction {
  type: OrderTypes.ADD_INVOICE_TYPE;
  payload: { value: string };
}

export interface AddProductFailureAction {
  type: OrderTypes.ADD_PRODUCT_FAILURE;
  payload: { message: string };
}

export interface PlaceOrderAction {
  type: OrderTypes.PLACE_ORDER_REQUEST;
  payload: {
    orderId: string;
    locale: string;
    electronicInvoiceDocumentType?: string;
  };
}

export interface PlaceOrderSuccessAction {
  type: OrderTypes.PLACE_ORDER_SUCCESS;
  payload: { order: Pick<OrderDetails, 'status'> };
}

export interface PlaceOrderFailureAction {
  type: OrderTypes.PLACE_ORDER_FAILURE;
  payload: { message: string };
}

export interface UnplaceOrderAction {
  type: OrderTypes.UNPLACE_ORDER_REQUEST;
  payload: {
    paymentMethod: PaymentMethod;
    errorMessage?: string;
    errorCode?: string;
    country?: string;
    loadLocalOrderFn: () => Promise<Order>;
  };
}

export interface UnplaceOrderSuccessAction {
  type: OrderTypes.UNPLACE_ORDER_SUCCESS;
}

export interface UnplaceOrderFailureAction {
  type: OrderTypes.UNPLACE_ORDER_FAILURE;
  payload: { message: string };
}

export interface ApplyCouponAction {
  type: OrderTypes.APPLY_COUPON_REQUEST;
  payload: { couponCode: string; updateCouponLimits?: boolean };
}

export interface ApplyCouponSuccessAction {
  type: OrderTypes.APPLY_COUPON_SUCCESS;
  payload: { order: OrderDetails };
}

export interface ApplyCouponFailureAction {
  type: OrderTypes.APPLY_COUPON_FAILURE;
  payload: { errorMessage: string };
}

export interface ResetCouponAction {
  type: OrderTypes.RESET_COUPON_REQUEST;
}

export interface ChangeProductQuantityAction {
  type: OrderTypes.CHANGE_PRODUCT_QUANTITY;
  payload: {
    lineItemId: string;
    quantity: number;
    contentfulId: CFProductId;
  };
}

export interface ChangeProductQuantitySuccessAction {
  type: OrderTypes.CHANGE_PRODUCT_QUANTITY_SUCCESS;
}

export interface ChangeProductQuantityFailureAction {
  type: OrderTypes.CHANGE_PRODUCT_QUANTITY_FAILURE;
}

export interface RemoveProductAction {
  type: OrderTypes.REMOVE_PRODUCT;
  payload: { contentfulId: CFProductId };
}

export interface RemoveProductSuccessAction {
  type: OrderTypes.REMOVE_PRODUCT_SUCCESS;
  payload: { lineItemId: string };
}

export interface RemoveProductFailureAction {
  type: OrderTypes.REMOVE_PRODUCT_FAILURE;
  payload: { lineItemId: string };
}

export interface UpdateNotificationAction {
  type: OrderTypes.UPDATE_NOTIFICATION;
  payload: {
    type: OrderState['operationStatus']['type'];
    showNotification: boolean;
  };
}

export interface ResetOrderAction {
  type: OrderTypes.RESET_ORDER;
}

export type OrderActions =
  | LoadOrderAction
  | LoadOrderSuccessAction
  | LoadOrderFailureAction
  | LoadProductsAction
  | AddProductAction
  | AddProductSuccessAction
  | AddProductFailureAction
  | ProposeBusinessAccountAction
  | SelectBusinessAccountAction
  | PlaceOrderAction
  | PlaceOrderSuccessAction
  | PlaceOrderFailureAction
  | UnplaceOrderAction
  | UnplaceOrderSuccessAction
  | UnplaceOrderFailureAction
  | ApplyCouponAction
  | ApplyCouponSuccessAction
  | ApplyCouponFailureAction
  | ResetCouponAction
  | ChangeProductQuantityAction
  | ChangeProductQuantitySuccessAction
  | ChangeProductQuantityFailureAction
  | RemoveProductAction
  | RemoveProductSuccessAction
  | RemoveProductFailureAction
  | UpdateNotificationAction
  | AddInvoiceTypeAction
  | ResetOrderAction;

/**
 * State types
 */

export type OrderStatuses =
  | 'draft'
  | 'editing'
  | 'pending'
  | 'placed'
  | 'approved'
  | 'cancelled'
  | 'placing';

export interface OrderDetails {
  id: string;
  number: Maybe<string>;
  status: OrderStatuses;
  formattedTotalAmount: string;
  /**
   * `formattedSubtotalAmount` contains the order price, without taxes or delivery rates.
   */
  formattedSubtotalAmount: string;
  /**
   * `formattedTotalTaxAmount` represents the value for the VAT price to be
   * charged during the checkout.
   */
  formattedTotalTaxAmount: string;
  /**
   * `taxRate` represents the value for the VAT rate to be
   * calculated for the user.
   */
  formattedTotalAmountWithTaxes: string;
  formattedDiscountAmount: Maybe<string>;
  discountAmountCents: Maybe<number>;
  couponCode: Maybe<string>;
  taxRate: Maybe<number>;
  totalAmountFloat: Maybe<number>;
  totalAmountWithTaxesCents: Maybe<number>;
  totalAmountWithTaxesFloat: Maybe<number>;
  currencyCode: Maybe<string>;
  metadata: Record<string, unknown>;
  customerEmail: Maybe<string>;
}

export type CLOrder = Order & { status: OrderStatuses };

export interface OrderLineItem {
  id: string;
  code: string;
  /**
   * formattedUnitAmount: Full price, without discounts
   * */
  formattedUnitAmount: string;
  /**
   * formattedTotalAmount: Price with discounts applied
   * */
  formattedTotalAmount: string;
  /**
   * discountCents: applied discount amount in monetary cents.
   * */
  discountCents: number;
  /**
   * discountRate: the rate of the discountCents over
   * the unitAmount of the product.
   * */
  discountRate: number;
  quantity: number;
  reference: Maybe<CFProductId>;
  unitAmountFloat: Maybe<number>;
  amountFloat: Maybe<number>;
  discountFloat: Maybe<number>;
  totalAmountFloat: number;
}

export interface OrderCoupon {
  couponCode: string;
  loading: boolean;
  hasError: boolean;
}

export interface OrderState {
  readonly orderDetails: OrderDetails;
  readonly products: OrderLineItem[];
  readonly operationStatus: {
    type?:
      | 'addProduct'
      | 'removeProduct'
      | 'changeProductQuantity'
      | 'removeOutOfStockProduct';
    loading: boolean;
    productReference: string;
    showNotification: boolean;
  };
  readonly loading: boolean;
  readonly error: boolean;
  readonly coupon: OrderCoupon;
  readonly isBusinessAccountProposed: boolean;
  readonly isBusinessAccountSelected: boolean;
  readonly invoiceDocumentType: string;
}
