import { DEFAULT_COUNTRY_CODE } from 'shared/constants/CountryConstants';
import {
  findAppropriateCountryForUrl,
  getCountryCodeFromISOLocale,
} from 'shared/i18n/helpers';
import { retrieveGlobalLocale } from 'shared/services/OrderInformationService.globals';
import {
  EVENTS,
  type PurchaseEventCustomData,
  type ManageProductCustomData,
} from 'shared/services/tracker/types';
import isServer from 'shared/utils/is-server';

import { pythia } from '../services';

import { PythiaStatuses, type PythiaEvents } from './types';

type ManageProductEvents =
  | EVENTS.ADD_PRODUCT
  | EVENTS.ADD_PRODUCT_FAILURE
  | EVENTS.CHANGE_PRODUCT_QUANTITY
  | EVENTS.CHANGE_PRODUCT_QUANTITY_FAILURE
  | EVENTS.REMOVE_PRODUCT
  | EVENTS.REMOVE_PRODUCT_FAILURE;

const PYTHIA_EVENTS: PythiaEvents = {
  [EVENTS.SIGNUP]: {
    name: 'signup',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.SIGNUP_FAILURE]: {
    name: 'signup',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.LOGIN]: {
    name: 'login',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.LOGIN_FAILURE]: {
    name: 'login',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.PURCHASE]: {
    name: 'purchase_attempt',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.PURCHASE_FAILURE]: {
    name: 'purchase_attempt',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.ADD_PRODUCT]: {
    name: 'order_add_line_item',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.ADD_PRODUCT_FAILURE]: {
    name: 'order_add_line_item',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.CHANGE_PRODUCT_QUANTITY]: {
    name: 'change_product_quantity_in_order',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.CHANGE_PRODUCT_QUANTITY_FAILURE]: {
    name: 'change_product_quantity_in_order',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.REMOVE_PRODUCT]: {
    name: 'remove_product_from_order',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.REMOVE_PRODUCT_FAILURE]: {
    name: 'remove_product_from_order',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.ORDER_PLACED]: {
    name: 'order_placed',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.ORDER_PLACEMENT_FAILURE]: {
    name: 'order_placed',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.OPTIMIZELY_INIT]: {
    name: 'optimizely_init',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.OPTIMIZELY_INIT_FAILURE]: {
    name: 'optimizely_init',
    status: PythiaStatuses.FAILED,
  },
  [EVENTS.ECOM_CLIENT_INIT]: {
    name: 'ecom_client_init',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.ECOM_CLIENT_INIT_FAILURE]: {
    name: 'ecom_client_init',
    status: PythiaStatuses.FAILED,
  },
};

export const handler =
  (eventName: EVENTS) =>
  ({
    country = getCountryCodeFromISOLocale(retrieveGlobalLocale()),
    ...data
  } = {}): void => {
    pythia.send({
      tags: { country },
      counterMetrics: [
        {
          name: PYTHIA_EVENTS[eventName].name,
          labels: {
            ...data,
            status: PYTHIA_EVENTS[eventName].status,
            country,
          },
        },
      ],
    });
  };

export const handleOptimizelyInit =
  (eventName: EVENTS) =>
  ({
    country = !isServer && findAppropriateCountryForUrl(window.location.href),
    ...data
  } = {}): void => {
    pythia.send({
      counterMetrics: [
        {
          name: PYTHIA_EVENTS[eventName].name,
          labels: {
            ...data,
            status: PYTHIA_EVENTS[eventName].status,
            country,
          },
        },
      ],
    });
  };

export const handlePurchase =
  (eventName: EVENTS.PURCHASE | EVENTS.PURCHASE_FAILURE) =>
  (customData: PurchaseEventCustomData): void => {
    const { paymentMethod, country = DEFAULT_COUNTRY_CODE } = customData;
    pythia.send({
      tags: { country },
      counterMetrics: [
        {
          name: PYTHIA_EVENTS[eventName].name,
          labels: {
            payment_method: paymentMethod,
            status: PYTHIA_EVENTS[eventName].status,
            country,
          },
        },
      ],
    });
  };

export const handleManageProduct =
  (eventName: ManageProductEvents) =>
  (data: ManageProductCustomData): void => {
    const country = data?.country || DEFAULT_COUNTRY_CODE;
    pythia.send({
      tags: { country },
      counterMetrics: [
        {
          name: PYTHIA_EVENTS[eventName].name,
          labels: {
            status: PYTHIA_EVENTS[eventName].status,
            country,
          },
        },
      ],
    });
  };
