import { EVENTS } from 'shared/services/tracker/types';

import { pythia } from '../services';

import { PythiaStatuses, type PythiaEvents } from './types';

export const PYTHIA_EVENTS: PythiaEvents = {
  [EVENTS.CREATE_CUSTOMER]: {
    name: 'create_customer',
    status: PythiaStatuses.SUCCESSFUL,
  },
  [EVENTS.CREATE_CUSTOMER_FAILURE]: {
    name: 'create_customer',
    status: PythiaStatuses.FAILED,
  },
};

export const generateCreateCustomer =
  (event: EVENTS) =>
  (data = {}): void =>
    pythia.send({
      counterMetrics: [
        {
          name: PYTHIA_EVENTS[event].name,
          labels: {
            status: PYTHIA_EVENTS[event].status,
            ...data,
          },
        },
      ],
    });

export const handleCreateCustomer = generateCreateCustomer(
  EVENTS.CREATE_CUSTOMER,
);

export const handleCreateCustomerFailure = generateCreateCustomer(
  EVENTS.CREATE_CUSTOMER_FAILURE,
);
