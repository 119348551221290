import Script from 'next/script';

/** https://github.com/sumup/legacy-browsers */
export function BrowserSupportScript(): JSX.Element {
  return (
    <Script
      id="script-browser-support"
      strategy="afterInteractive"
      type="text/javascript"
      src="https://static.sumup.com/legacy-browsers/check-support.js"
    />
  );
}
