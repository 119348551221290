import { getAttributionParams } from 'shared/services/attribution';
import type { AuthEventCustomData } from 'shared/services/tracker/types';

import { sendEvent } from './service';

/**
 * ADD_PRODUCT handler
 * @param customData
 */
export function addProductHandler(): void {
  sendEvent('addToCart');
}

/**
 * SIGNUP handler
 * @param customData
 */
export function signupHandler(customData: AuthEventCustomData): void {
  const { referrer } = customData;
  const { referral_code: referralCode } = getAttributionParams();
  const referAction = referrer || referralCode;

  sendEvent('Signup');

  if (referAction) {
    sendEvent('ReferralSignup');
  }
}

/**
 * LOGIN handler
 * @param customData
 */
export function loginHandler(): void {
  sendEvent('login');
}

/**
 * PURCHASE handler
 * @param customData
 */
export function purchaseCheckoutSuccessHandler(): void {
  sendEvent('Checkout');
}

/**
 * PURCHASE_FAILURE handler
 */
export function purchaseFailureHandler(): void {
  sendEvent('checkout_failed');
}

/**
 * GO_TO_ACTIVATION handler
 * @param customData
 */
export function goToActivationHandler(): void {
  sendEvent('go_complete_account');
}

/**
 * GO_TO_CHECKOUT handler
 * @param customData
 */
export function goToCheckoutHandler(): void {
  sendEvent('go_to_checkout');
}

export function proceedAsRegisteredUserHandler(): void {
  sendEvent('proceed_as_registered_user');
}

/**
 * MORE_INFO handler
 * @param customData
 */
export function moreInfoHandler(): void {
  sendEvent('more_info');
}

/**
 * SKIP_SHOP handler
 */
export function skipShopHandler(): void {
  sendEvent('skip_shop');
}

/**
 * GO_TO_PAYMENT handler
 */
export function goToPaymentHandler(): void {
  sendEvent('go_to_payment');
}

/**
 * VOUCHER_APPLIED handler
 */
export function voucherAppliedHandler(): void {
  sendEvent('voucher_applied');
}

/**
 * GO_TO_WEBSITE handler
 */
export function goToWebsiteHandler(): void {
  sendEvent('go_to_website');
}

/**
 * BA_LEARN_MORE_OPEN handler
 */
export function businessAccountLearnMoreOpenHandler(): void {
  sendEvent('ba_learn_more_open');
}

/**
 * BA_LEARN_MORE_CLOSE handler
 */
export function businessAccountLearnMoreCloseHandler(): void {
  sendEvent('ba_learn_more_close');
}

/**
 * BA_SELECTED handler
 */
export function businessAccountSelectedHandler(): void {
  sendEvent('ba_selected');
}

/**
 * BA_UNSELECTED handler
 */
export function businessAccountUnselectedHandler(): void {
  sendEvent('ba_unselected');
}

/**
 * BA_CONDITIONS_MODAL_OPEN handler
 */
export function businessAccountConditionsModalOpenHandler(): void {
  sendEvent('ba_conditions_modal_open');
}

/**
 * BA_CONDITIONS_MODAL_CLOSE handler
 */
export function businessAccountConditionsModalCloseHandler(): void {
  sendEvent('ba_conditions_modal_close');
}
